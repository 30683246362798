import { ChangeDetectionStrategy, Component, Inject } from '@angular/core';
import { TranslateModule } from '@ngx-translate/core';
import { MatButtonModule } from '@angular/material/button';
import { NgIf, DatePipe } from '@angular/common';
import { SvgIconComponent } from '@ngneat/svg-icon';
import { Subject } from 'rxjs';
import { Router } from '@angular/router';
import { AuthService } from '../../../../services/auth.service';
import { DIALOG_DATA } from '../../../dialog/dialog.tokens';
import { DialogData } from '../../../dialog/interfaces/dialog-data.interface';
import { CONFIG_TOKEN } from '../../../../config/config.token';
import { GlobalConfig } from '../../../../config/global-config.interface';
import { DialogService } from '../../../dialog/dialog.service';
import { accountChangeDialogPayload } from './account-change-dialog-payload.interface';

@Component({
  selector: 'account-change-dialog',
  templateUrl: './account-change-dialog.component.html',
  styleUrls: ['./account-change-dialog.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [SvgIconComponent, NgIf, MatButtonModule, DatePipe, TranslateModule],
})
export class AccountChangeDialogComponent {
  public readonly imgPath = `assets/icons/dark_logo.svg`;
  private readonly destroyed$ = new Subject<void>();
  public payload: accountChangeDialogPayload;
  constructor(
    @Inject(CONFIG_TOKEN) private readonly config: GlobalConfig,
    @Inject(DIALOG_DATA) public readonly data: DialogData<accountChangeDialogPayload>,
    private readonly authService: AuthService,
    private readonly dialogService: DialogService,
    private readonly router: Router
  ) {
    this.payload = this.data.payload;
  }

  public currentAccount() {
    // Reload current account
    const segments = this.router.url.split('/');
    this.dialogService.closeAll();
    this.router.navigateByUrl(segments.slice(0, 2).join('/'));
  }

  public switchAccounts() {
    // Move to object account
    this.authService.changePrimaryAccount(this.data.payload.objectAccount.accountId, false);
  }

  public ngOnDestroy(): void {
    this.destroyed$.next();
    this.destroyed$.complete();
  }
}
